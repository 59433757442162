body {
  background-color: #2C2F33;
}

.App {
  color: #ffffff;
}

.App-jumbo {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 4em;
  font-weight: bold;
}

.App-subtitle {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 1em;
  padding-bottom: 20px;
  font-weight: normal;
  color: #99AAB5;
}

.Group-title {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 2em;
  font-weight: bold;
}

.Group-subtitle {
  margin-top: 10px;
  margin-left: 20px;
  font-size: 1em;
  padding-bottom: 10px;
  color: #99AAB5;
}

.selectBox {
  width: 20%;
  margin-left: 20px;
}

.footer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #23272A;
}

.footer a {
  color: #7289DA;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
